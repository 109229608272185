import * as classnames from 'classnames';
import { darken } from 'polished';
import { deprecatedCss, ShelfIcon } from '../../common-elements';
import styled, { css } from '../../styled-components';
export const OperationBadge = styled.span.attrs((props: {
  type: string;
}) => ({
  className: `operation-type ${props.type}`
})).withConfig({
  componentId: "sc-10atcbm-0"
})(["width:32px;display:inline-block;height:", ";line-height:", ";background-color:#333;border-radius:3px;background-repeat:no-repeat;background-position:6px 4px;font-size:7px;font-family:Verdana;color:white;text-transform:uppercase;text-align:center;font-weight:bold;vertical-align:middle;margin-right:6px;margin-top:2px;&.get{background-color:", ";}&.post{background-color:", ";}&.put{background-color:", ";}&.options{background-color:", ";}&.patch{background-color:", ";}&.delete{background-color:", ";}&.basic{background-color:", ";}&.link{background-color:", ";}&.head{background-color:", ";}"], props => props.theme.typography.code.fontSize, props => props.theme.typography.code.fontSize, props => props.theme.colors.http.get, props => props.theme.colors.http.post, props => props.theme.colors.http.put, props => props.theme.colors.http.options, props => props.theme.colors.http.patch, props => props.theme.colors.http.delete, props => props.theme.colors.http.basic, props => props.theme.colors.http.link, props => props.theme.colors.http.head);

function menuItemActiveBg(depth, {
  theme
}): string {
  if (depth > 1) {
    return darken(0.1, theme.menu.backgroundColor);
  } else if (depth === 1) {
    return darken(0.05, theme.menu.backgroundColor);
  } else {
    return '';
  }
}

export const MenuItemUl = styled.ul.withConfig({
  componentId: "sc-10atcbm-1"
})(["margin:0;padding:0;& &{font-size:0.929em;}", ";"], props => props.expanded ? '' : 'display: none;');
export const MenuItemLi = styled.li.withConfig({
  componentId: "sc-10atcbm-2"
})(["list-style:none inside none;overflow:hidden;text-overflow:ellipsis;padding:0;", ";"], props => props.depth === 0 ? 'margin-top: 15px' : '');
export const menuItemDepth = {
  0: css(["opacity:0.7;text-transform:", ";font-size:0.8em;padding-bottom:0;cursor:default;color:", ";"], ({
    theme
  }) => theme.menu.groupItems.textTransform, props => props.theme.menu.textColor),
  1: css(["font-size:0.929em;text-transform:", ";&:hover{color:", ";}"], ({
    theme
  }) => theme.menu.level1Items.textTransform, props => props.theme.colors.primary.main),
  2: css(["color:", ";"], props => props.theme.menu.textColor)
};
export interface MenuItemLabelType {
  depth: number;
  active: boolean;
  deprecated?: boolean;
  type?: string;
}
export const MenuItemLabel = styled.label.attrs((props: MenuItemLabelType) => ({
  role: 'menuitem',
  className: classnames('-depth' + props.depth, {
    active: props.active
  })
})).withConfig({
  componentId: "sc-10atcbm-3"
})(["cursor:pointer;color:", ";margin:0;padding:12.5px ", "px;", " display:flex;justify-content:space-between;font-family:", ";", ";background-color:", ";", ";&:hover{background-color:", ";}", "{height:", ";width:", ";polygon{fill:", ";}}"], props => props.active ? props.theme.colors.primary.main : props.theme.menu.textColor, props => props.theme.spacing.unit * 4, ({
  depth,
  type,
  theme
}) => type === 'section' && depth > 1 && 'padding-left: ' + theme.spacing.unit * 8 + 'px;' || '', props => props.theme.typography.headings.fontFamily, props => menuItemDepth[props.depth], props => props.active ? menuItemActiveBg(props.depth, props) : '', props => props.deprecated && deprecatedCss || '', props => menuItemActiveBg(props.depth, props), ShelfIcon, ({
  theme
}) => theme.menu.arrow.size, ({
  theme
}) => theme.menu.arrow.size, ({
  theme
}) => theme.menu.arrow.color);
export const MenuItemTitle = styled.span.withConfig({
  componentId: "sc-10atcbm-4"
})(["display:inline-block;vertical-align:middle;width:", ";overflow:hidden;text-overflow:ellipsis;"], props => props.width ? props.width : 'auto');
export const RedocAttribution = styled.div.withConfig({
  componentId: "sc-10atcbm-5"
})(["", ";"], ({
  theme
}) => `
  font-size: 0.8em;
  margin-top: ${theme.spacing.unit * 2}px;
  padding: 0 ${theme.spacing.unit * 4}px;
  text-align: left;

  opacity: 0.7;

  a,
  a:visited,
  a:hover {
    color: ${theme.menu.textColor} !important;
    border-top: 1px solid ${darken(0.1, theme.menu.backgroundColor)};
    padding: ${theme.spacing.unit}px 0;
    display: block;
  }
`);
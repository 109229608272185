import styled from '../styled-components';
export const OneOfList = styled.ul.withConfig({
  componentId: "y3ai9j-0"
})(["margin:0 0 3px 0;padding:0;list-style:none;display:inline-block;"]);
export const OneOfLabel = styled.span.withConfig({
  componentId: "y3ai9j-1"
})(["font-size:0.9em;margin-right:10px;color:", ";font-family:Montserrat;}"], props => props.theme.colors.primary.main);
export const OneOfButton = styled.li.withConfig({
  componentId: "y3ai9j-2"
})(["display:inline-block;margin-right:10px;font-size:0.8em;cursor:pointer;border:1px solid ", ";padding:2px 10px;", ""], props => props.theme.colors.primary.main, props => {
  if (props.active) {
    return `
      color: white;
      background-color: ${props.theme.colors.primary.main};
      `;
  } else {
    return `
        color: ${props.theme.colors.primary.main};
        background-color: white;
      `;
  }
});
export const ArrayOpenningLabel = styled.div.withConfig({
  componentId: "y3ai9j-3"
})(["font-size:0.9em;font-family:", ";&::after{content:' [';}"], props => props.theme.typography.code.fontFamily);
export const ArrayClosingLabel = styled.div.withConfig({
  componentId: "y3ai9j-4"
})(["font-size:0.9em;font-family:", ";&::after{content:']';}"], props => props.theme.typography.code.fontFamily);
import { darken } from 'polished';
import { Tabs as ReactTabs } from 'react-tabs';
import styled from '../styled-components';
export { Tab, TabList, TabPanel } from 'react-tabs';
export const Tabs = styled(ReactTabs).withConfig({
  componentId: "sc-1vrf7wa-0"
})(["> ul{list-style:none;padding:0;margin:0;margin:0 -5px;> li{padding:5px 10px;display:inline-block;background-color:", ";border-bottom:1px solid rgba(0,0,0,0.5);cursor:pointer;text-align:center;outline:none;color:", ";margin:0 ", ";border:1px solid ", ";border-radius:5px;min-width:60px;font-size:0.9em;font-weight:bold;&.react-tabs__tab--selected{color:", ";background:", ";}&:only-child{flex:none;min-width:100px;}&.tab-success{color:", ";}&.tab-redirect{color:", ";}&.tab-info{color:", ";}&.tab-error{color:", ";}}}> .react-tabs__tab-panel{background:", ";& > div,& > pre{padding:", "px;margin:0;}& > div > pre{padding:0;}}"], ({
  theme
}) => theme.codeSample.backgroundColor, ({
  theme
}) => darken(theme.colors.tonalOffset, theme.rightPanel.textColor), ({
  theme
}) => `${theme.spacing.unit}px ${theme.spacing.unit}px ${theme.spacing.unit}px`, ({
  theme
}) => darken(0.05, theme.codeSample.backgroundColor), props => props.theme.colors.text.primary, ({
  theme
}) => theme.rightPanel.textColor, props => props.theme.colors.responses.success.color, props => props.theme.colors.responses.redirect.color, props => props.theme.colors.responses.info.color, props => props.theme.colors.responses.error.color, ({
  theme
}) => theme.codeSample.backgroundColor, props => props.theme.spacing.unit * 4);
export const SmallTabs = styled(Tabs).withConfig({
  componentId: "sc-1vrf7wa-1"
})(["> ul{display:block;> li{padding:2px 5px;min-width:auto;margin:0 15px 0 0;font-size:13px;font-weight:normal;border-bottom:1px dashed;color:", ";border-radius:0;background:none;&:last-child{margin-right:0;}&.react-tabs__tab--selected{color:", ";background:none;}}}> .react-tabs__tab-panel{& > div,& > pre{padding:", " 0;}}"], ({
  theme
}) => darken(theme.colors.tonalOffset, theme.rightPanel.textColor), ({
  theme
}) => theme.rightPanel.textColor, props => props.theme.spacing.unit * 2);
import * as React from 'react';
import { Schema, SchemaProps } from './Schema';
import { ArrayClosingLabel, ArrayOpenningLabel } from '../../common-elements';
import styled from '../../styled-components';
const PaddedSchema = styled.div.withConfig({
  componentId: "sc-71zhzb-0"
})(["padding-left:", "px;"], ({
  theme
}) => theme.spacing.unit * 2);
export class ArraySchema extends React.PureComponent<SchemaProps> {
  render() {
    const itemsSchema = this.props.schema.items!;
    return <div>
        <ArrayOpenningLabel> Array </ArrayOpenningLabel>
        <PaddedSchema>
          <Schema {...this.props} schema={itemsSchema} />
        </PaddedSchema>
        <ArrayClosingLabel />
      </div>;
  }

}
import * as React from 'react';
import { darken } from 'polished';
import styled from '../../styled-components';
import { MenuItemLabel } from '../SideMenu/styled.elements';
export const SearchWrap = styled.div.withConfig({
  componentId: "k4h4y9-0"
})(["padding:5px 0;"]);
export const SearchInput = styled.input.attrs(() => ({
  className: 'search-input'
})).withConfig({
  componentId: "k4h4y9-1"
})(["width:calc(100% - ", "px);box-sizing:border-box;margin:0 ", "px;padding:5px ", "px 5px ", "px;border:0;border-bottom:1px solid ", ";font-family:", ";font-weight:bold;font-size:13px;color:", ";background-color:transparent;outline:none;"], props => props.theme.spacing.unit * 8, props => props.theme.spacing.unit * 4, props => props.theme.spacing.unit * 2, props => props.theme.spacing.unit * 4, ({
  theme
}) => darken(0.1, theme.menu.backgroundColor), ({
  theme
}) => theme.typography.fontFamily, props => props.theme.menu.textColor);
export const SearchIcon = styled((props: {
  className?: string;
}) => <svg className={props.className} version="1.1" viewBox="0 0 1000 1000" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px">
    <path d="M968.2,849.4L667.3,549c83.9-136.5,66.7-317.4-51.7-435.6C477.1-25,252.5-25,113.9,113.4c-138.5,138.3-138.5,362.6,0,501C219.2,730.1,413.2,743,547.6,666.5l301.9,301.4c43.6,43.6,76.9,14.9,104.2-12.4C981,928.3,1011.8,893,968.2,849.4z M524.5,522c-88.9,88.7-233,88.7-321.8,0c-88.9-88.7-88.9-232.6,0-321.3c88.9-88.7,233-88.7,321.8,0C613.4,289.4,613.4,433.3,524.5,522z" />
  </svg>).attrs({
  className: 'search-icon'
}).withConfig({
  componentId: "k4h4y9-2"
})(["position:absolute;left:", "px;height:1.8em;width:0.9em;path{fill:", ";}"], props => props.theme.spacing.unit * 4, props => props.theme.menu.textColor);
export const SearchResultsBox = styled.div.withConfig({
  componentId: "k4h4y9-3"
})(["padding:", "px 0;background-color:#ededed;min-height:150px;max-height:250px;border-top:1px solid #e1e1e1;border-bottom:1px solid #e1e1e1;margin-top:10px;line-height:1.4;font-size:0.9em;", "{padding-top:6px;padding-bottom:6px;&:hover{background-color:#e1e1e1;}> svg{display:none;}&.active{background-color:#e1e1e1;}}"], props => props.theme.spacing.unit, MenuItemLabel);
export const ClearIcon = styled.i.withConfig({
  componentId: "k4h4y9-4"
})(["position:absolute;display:inline-block;width:", "px;text-align:center;right:", "px;line-height:2em;vertical-align:middle;margin-right:2px;cursor:pointer;font-style:normal;color:'#666';"], props => props.theme.spacing.unit * 2, props => props.theme.spacing.unit * 4);
import { headerCommonMixin, linkifyMixin } from '../../common-elements';
import { PrismDiv } from '../../common-elements/PrismDiv';
import styled, { css, extensionsHook, ResolvedThemeInterface } from '../../styled-components';
import { StyledComponent } from 'styled-components';
export const linksCss = css(["a{text-decoration:none;color:", ";&:visited{color:", ";}&:hover{color:", ";}}"], props => props.theme.typography.links.color, props => props.theme.typography.links.visited, props => props.theme.typography.links.hover);
export const StyledMarkdownBlock = styled((PrismDiv as StyledComponent<'div', ResolvedThemeInterface, {
  compact?: boolean;
  inline?: boolean;
}>)).withConfig({
  componentId: "sc-1m0b31p-0"
})(["font-family:", ";font-weight:", ";line-height:", ";p{&:last-child{margin-bottom:0;}}", " ", " h1{", ";color:", ";margin-top:0;}h2{", ";color:", ";}code{color:", ";background-color:", ";font-family:", ";border-radius:2px;border:1px solid rgba(38,50,56,0.1);padding:0 ", "px;font-size:", ";font-weight:", ";word-break:break-word;}pre{font-family:", ";white-space:", ";background-color:#263238;color:white;padding:", "px;overflow-x:auto;line-height:normal;border-radius:0px border:1px solid rgba(38,50,56,0.1);code{background-color:transparent;color:white;padding:0;&:before,&:after{content:none;}}}blockquote{margin:0;margin-bottom:1em;padding:0 15px;color:#777;border-left:4px solid #ddd;}img{max-width:100%;box-sizing:content-box;}ul,ol{padding-left:2em;margin:0;margin-bottom:1em;ul,ol{margin-bottom:0;margin-top:0;}}table{display:block;width:100%;overflow:auto;word-break:normal;word-break:keep-all;border-collapse:collapse;border-spacing:0;margin-top:1.5em;margin-bottom:1.5em;}table tr{background-color:#fff;border-top:1px solid #ccc;&:nth-child(2n){background-color:", ";}}table th,table td{padding:6px 13px;border:1px solid #ddd;}table th{text-align:left;font-weight:bold;}", ";", " ", ";"], props => props.theme.typography.fontFamily, props => props.theme.typography.fontWeightRegular, props => props.theme.typography.lineHeight, ({
  compact
}) => compact && `
    p:first-child {
      margin-top: 0;
    }
    p:last-child {
      margin-bottom: 0;
    }
  `, ({
  inline
}) => inline && ` p {
    display: inline-block;
  }`, headerCommonMixin(1), props => props.theme.colors.primary.main, headerCommonMixin(2), props => props.theme.colors.text.primary, ({
  theme
}) => theme.typography.code.color, ({
  theme
}) => theme.typography.code.backgroundColor, props => props.theme.typography.code.fontFamily, ({
  theme
}) => theme.spacing.unit, props => props.theme.typography.code.fontSize, ({
  theme
}) => theme.typography.code.fontWeight, props => props.theme.typography.code.fontFamily, ({
  theme
}) => theme.typography.code.wrap ? 'pre-wrap' : 'pre', props => props.theme.spacing.unit * 4, ({
  theme
}) => theme.schema.nestedBackground, linkifyMixin('.share-link'), linksCss, extensionsHook('Markdown'));
import { H1, MiddlePanel } from '../../common-elements';
import styled, { extensionsHook } from '../../styled-components';
const delimiterWidth = 15;
export const ApiInfoWrap = MiddlePanel;
export const ApiHeader = styled(H1).withConfig({
  componentId: "sc-1mcjnf5-0"
})(["margin-top:0;margin-bottom:0.5em;", ";"], extensionsHook('ApiHeader'));
export const DownloadButton = styled.a.withConfig({
  componentId: "sc-1mcjnf5-1"
})(["border:1px solid ", ";color:", ";font-weight:normal;margin-left:0.5em;padding:4px 8px 4px;display:inline-block;text-decoration:none;cursor:pointer;", ";"], props => props.theme.colors.primary.main, props => props.theme.colors.primary.main, extensionsHook('DownloadButton'));
export const InfoSpan = styled.span.withConfig({
  componentId: "sc-1mcjnf5-2"
})(["&::before{content:'|';display:inline-block;opacity:0.5;width:", "px;text-align:center;}&:last-child::after{display:none;}"], delimiterWidth);
export const InfoSpanBoxWrap = styled.div.withConfig({
  componentId: "sc-1mcjnf5-3"
})(["overflow:hidden;"]);
export const InfoSpanBox = styled.div.withConfig({
  componentId: "sc-1mcjnf5-4"
})(["display:flex;flex-wrap:wrap;margin-left:-", "px;"], delimiterWidth);
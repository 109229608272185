import * as React from 'react';
import { StoreConsumer } from '../components/StoreBuilder';
import styled, { css } from '../styled-components';
import { HistoryService } from '../services'; // tslint:disable-next-line

export const linkifyMixin = className => css(["", "{cursor:pointer;margin-left:-20px;padding:0;line-height:1;width:20px;display:inline-block;}", ":before{content:'';width:15px;height:15px;background-size:contain;background-image:url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeD0iMCIgeT0iMCIgd2lkdGg9IjUxMiIgaGVpZ2h0PSI1MTIiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA1MTIgNTEyIiB4bWw6c3BhY2U9InByZXNlcnZlIj48cGF0aCBmaWxsPSIjMDEwMTAxIiBkPSJNNDU5LjcgMjMzLjRsLTkwLjUgOTAuNWMtNTAgNTAtMTMxIDUwLTE4MSAwIC03LjktNy44LTE0LTE2LjctMTkuNC0yNS44bDQyLjEtNDIuMWMyLTIgNC41LTMuMiA2LjgtNC41IDIuOSA5LjkgOCAxOS4zIDE1LjggMjcuMiAyNSAyNSA2NS42IDI0LjkgOTAuNSAwbDkwLjUtOTAuNWMyNS0yNSAyNS02NS42IDAtOTAuNSAtMjQuOS0yNS02NS41LTI1LTkwLjUgMGwtMzIuMiAzMi4yYy0yNi4xLTEwLjItNTQuMi0xMi45LTgxLjYtOC45bDY4LjYtNjguNmM1MC01MCAxMzEtNTAgMTgxIDBDNTA5LjYgMTAyLjMgNTA5LjYgMTgzLjQgNDU5LjcgMjMzLjR6TTIyMC4zIDM4Mi4ybC0zMi4yIDMyLjJjLTI1IDI0LjktNjUuNiAyNC45LTkwLjUgMCAtMjUtMjUtMjUtNjUuNiAwLTkwLjVsOTAuNS05MC41YzI1LTI1IDY1LjUtMjUgOTAuNSAwIDcuOCA3LjggMTIuOSAxNy4yIDE1LjggMjcuMSAyLjQtMS40IDQuOC0yLjUgNi44LTQuNWw0Mi4xLTQyYy01LjQtOS4yLTExLjYtMTgtMTkuNC0yNS44IC01MC01MC0xMzEtNTAtMTgxIDBsLTkwLjUgOTAuNWMtNTAgNTAtNTAgMTMxIDAgMTgxIDUwIDUwIDEzMSA1MCAxODEgMGw2OC42LTY4LjZDMjc0LjYgMzk1LjEgMjQ2LjQgMzkyLjMgMjIwLjMgMzgyLjJ6Ii8+PC9zdmc+Cg==');opacity:0.5;visibility:hidden;display:inline-block;vertical-align:middle;}h1:hover > ", "::before,h2:hover > ", "::before,", ":hover::before{visibility:visible;}"], className, className, className, className, className);

const isModifiedEvent = event => !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);

export class Link extends React.Component<{
  to: string;
  className?: string;
  children?: any;
}> {
  navigate = (history: HistoryService, event) => {
    if (!event.defaultPrevented && // onClick prevented default
    event.button === 0 && // ignore everything but left clicks
    !isModifiedEvent(event) // ignore clicks with modifier keys
    ) {
        event.preventDefault();
        history.replace(this.props.to);
      }
  };

  render() {
    return <StoreConsumer>
        {store => <a className={this.props.className} href={store!.menu.history.linkForId(this.props.to)} onClick={this.navigate.bind(this, store!.menu.history)}>
            {this.props.children}
          </a>}
      </StoreConsumer>;
  }

}
const StyledShareLink = styled(Link).withConfig({
  componentId: "eb0njo-0"
})(["", ";"], linkifyMixin('&'));
export function ShareLink(props: {
  to: string;
}) {
  return <StyledShareLink to={props.to} />;
}
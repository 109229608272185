import styled from '../styled-components';
import { PrismDiv } from './PrismDiv';
export const SampleControls = styled.div.withConfig({
  componentId: "sc-1rd7dj7-0"
})(["opacity:0.4;transition:opacity 0.3s ease;text-align:right;> span{display:inline-block;padding:2px 10px;cursor:pointer;:hover{background:rgba(255,255,255,0.1);}}"]);
export const SampleControlsWrap = styled.div.withConfig({
  componentId: "sc-1rd7dj7-1"
})(["&:hover ", "{opacity:1;}"], SampleControls);
export const StyledPre = styled(PrismDiv.withComponent('pre')).withConfig({
  componentId: "sc-1rd7dj7-2"
})(["font-family:", ";font-size:", ";overflow-x:auto;margin:0;white-space:", ";"], props => props.theme.typography.code.fontFamily, props => props.theme.typography.code.fontSize, ({
  theme
}) => theme.typography.code.wrap ? 'pre-wrap' : 'pre');
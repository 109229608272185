import styled from '../../styled-components';
export const OperationEndpointWrap = styled.div.withConfig({
  componentId: "nrhuz6-0"
})(["cursor:pointer;position:relative;margin-bottom:5px;"]);
export const ServerRelativeURL = styled.span.withConfig({
  componentId: "nrhuz6-1"
})(["font-family:", ";margin-left:10px;flex:1;overflow-x:hidden;text-overflow:ellipsis;"], props => props.theme.typography.code.fontFamily);
export const EndpointInfo = styled.div.withConfig({
  componentId: "nrhuz6-2"
})(["padding:10px 30px 10px ", ";border-radius:", ";background-color:", ";display:flex;white-space:nowrap;align-items:center;border:", ";border-bottom:", ";transition:border-color 0.25s ease;", " .", "{color:", "}"], props => props.inverted ? '10px' : '20px', props => props.inverted ? '0' : '4px 4px 0 0', props => props.inverted ? 'transparent' : props.theme.codeSample.backgroundColor, props => props.inverted ? '0' : '1px solid transparent', props => props.inverted ? '1px solid #ccc' : '0', props => props.expanded && !props.inverted && `border-color: ${props.theme.colors.border.dark};` || '', ServerRelativeURL, props => props.inverted ? props.theme.colors.text.primary : '#ffffff');
export const HttpVerb = styled.span.attrs((props: {
  type: string;
}) => ({
  className: `http-verb ${props.type}`
})).withConfig({
  componentId: "nrhuz6-3"
})(["font-size:0.929em;line-height:20px;background-color:", ";color:#ffffff;padding:3px 10px;text-transform:uppercase;font-family:", ";margin:0;"], (props: any) => props.theme.colors.http[props.type] || '#999999', props => props.theme.typography.headings.fontFamily);
export const ServersOverlay = styled.div.withConfig({
  componentId: "nrhuz6-4"
})(["position:absolute;width:100%;z-index:100;background:#fafafa;color:#263238;box-sizing:border-box;box-shadow:0px 0px 6px rgba(0,0,0,0.33);overflow:hidden;border-bottom-left-radius:4px;border-bottom-right-radius:4px;transition:all 0.25s ease;", ""], props => props.expanded ? '' : 'transform: translateY(-50%) scaleY(0);');
export const ServerItem = styled.div.withConfig({
  componentId: "nrhuz6-5"
})(["padding:10px;"]);
export const ServerUrl = styled.div.withConfig({
  componentId: "nrhuz6-6"
})(["padding:5px;border:1px solid #ccc;background:#fff;word-break:break-all;color:", ";> span{color:", ";}"], props => props.theme.colors.primary.main, props => props.theme.colors.text.primary);